<template>
  <div class="row">
    <div class="col-12 col-lg-8 g">
        <div class="card">
            <div class="card-header">
                <h5>
                    <i class="fa fa-user-edit"></i>
                    ترحيل الصفوف
                </h5>
            </div>
            <div class="card-body">
                <div class="form-group">
                  <label for="">نقل من</label>
                  <select class="form-control" v-model="selected_class_to_move">
                    <option :value="null">-- اختر -- </option>
                    <option v-for="c in classes" :value="c" :key="c">{{ c }}</option>
                  </select>
                </div>
                <div class="form-group" v-if="!move_manual">
                  <label for="">نقل الى <a href="javascript:;" @click="move_manual = true"><u>كتابة يدوياً</u></a></label>
                  <select class="form-control" v-model="selected_class_to_move_to_it">
                    <option :value="null">-- اختر -- </option>
                    <option v-for="c in classes" :value="c" :key="c">{{ c }}</option>
                  </select>
                </div>
                <div class="form-group" v-if="move_manual">
                  <label for="">اكتب اسم/رقم الصف الجديد</label>
                  <input type="text"
                    class="form-control" v-model="selected_class_to_move_to_it">
                </div>
            <div class="form-group">
              <h5 for="">القسم (الذي سيكون عليه بعد الترحيل)</h5>
              <select
                class="form-control"
                name=""
                v-model="section_id"
                id=""
              >
                
                    <option :value="null">-- اختر -- </option>
                <template v-for="section in sections">
                  <option :value="section._id" :key="section._id">
                    {{ section.title }}
                  </option>
                </template>
              </select>
            </div>
                <div class="col-12 text-center g">
                    <button class="btn btn-primary" @click="moveNow()">
                        <i class="fa fa-user-edit"></i>
                        نقل الآن
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div class="col-12 col-lg-4 g">
        <div class="card">
            <div class="card-header">
                <h5>
                    <i class="fa fa-trash"></i>
                    حذف صف معين
                </h5>
            </div>
            <div class="card-body">
                <div class="form-group">
                  <label for="">اختر الصف</label>
                  <select class="form-control" v-model="selected_class_to_delete">
                    <option :value="null">-- اختر -- </option>
                    <option v-for="c in classes" :value="c" :key="c">{{ c }}</option>
                  </select>
                </div>
                <div class="col-12 text-center g">
                    <button class="btn btn-danger" @click="deleteNow()">
                        <i class="fa fa-user-times"></i>
                        حذف الآن
                    </button>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    data(){
        return {
            user: JSON.parse(localStorage.getItem("user")),
            classes: [],
            selected_class_to_delete: null,
            selected_class_to_move: null,
            selected_class_to_move_to_it: null,
            move_manual: false,
            sections: [],
            section_id: null
        }
    },
    created(){
        checkPer(this, "settings", "allowed");
        checkPer(this, "students", "delete");
        var g = this;
        $.post(api + "/admin/students/classes", {
            jwt: g.user.jwt,
        }).then(function (r) {
            g.classes = JSON.parse(r).response.classes
        });
        
      $.post(api + "/admin/sections/list", {
        jwt: g.user.jwt,
      }).then(function (r) {
        g.sections = JSON.parse(r).response;
      });
    },
    methods: {
        deleteNow(){
            var g = this;
            if(
                g.selected_class_to_delete
                &&
                checkPer(this, "settings", "allowed") 
                &&
                checkPer(this, "students", "delete")
            ){
                if(confirm("متأكد من حذف الصف المختار؟")){
                    $.post(api + "/admin/students/delete-class", {
                        jwt: g.user.jwt,
                        classname: g.selected_class_to_delete
                    }).then(function (r) {
                        alert("تم الحذف")
                        location.reload()
                    });
                }
            }
        },
        moveNow(){
            var g = this;
            if(
                g.selected_class_to_move
                &&
                g.selected_class_to_move_to_it
                &&
                g.section_id
                &&
                checkPer(this, "settings", "allowed") 
                &&
                checkPer(this, "students", "edit")
            ){
                if(confirm("متأكد من نقل الصف المختار؟")){
                    $.post(api + "/admin/students/move-class", {
                        jwt: g.user.jwt,
                        from: g.selected_class_to_move,
                        to: g.selected_class_to_move_to_it,
                        section_id: g.section_id
                    }).then(function (r) {
                        alert("تم النقل")
                        location.reload()
                    });
                }
            }
        }
    }
}
</script>

<style>

</style>